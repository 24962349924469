
const Report = {
  id: null,
  target: null,
  date_to: null,
  date_from: null,
  name: null,
  data: [],
};

export default Report
