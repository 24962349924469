<template>
  <div>
    <el-table stripe :data="rows">
      <el-table-column
        width="180px"
        :label="$t('datatable.username')"
        align="left">
        <div slot-scope="{ row }" class="text-truncate">
          {{ row.user }}
        </div>
      </el-table-column>
      <el-table-column
        v-for="(column, index) in columns" v-bind:key="index"
        width="100px"
        :label="column"
        align="center">
        <div slot-scope="{ row }" class="small">
          <div v-if="column === 'implut' || column === 'imptin'">
            {{row.values[column] | euro}}
          </div>
          <div v-else>
            {{row.values[column] ? row.values[column] : ''}}
          </div>

        </div>
      </el-table-column>
    </el-table>
    <p class="mt-3 text-uppercase">{{ $t('common.legend') }}</p>
    <ul class="list-group list-group-flush">
      <li v-for="(legend, index) in columns"
          v-bind:key="`legend-${index}`"
          class="standard-li">
        <div class="standard-label">
          <span class="text-primary">{{ legend }}</span>
          {{ $t('reports.callCenterOperatorBalancePerformanceTable.' + legend) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import Report from "@/models/report";

export default {
  name: "CallCenterOperatorBalancePerformanceTable",
  data() {
    return {
      columns: [
        'leauti',
        'leatot',
        'chitot',
        'orelav',
        'oretip',
        'frechi',
        'frelut',
        'implut',
        'imptin',
      ]
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    rows: function () {
      return Object.values(this.report?.data || {})
    },
  }
}
</script>

<style scoped>

</style>
