<template>
  <el-table
    width="100%"
    stripe
    show-summary
    :summary-method="getAmountSummaries"
    :data="data"
  >
    <el-table-column
      :label="$t('fields.courses')"
      :width="200"
      align="left"
    >
      <div slot-scope="{ row }">
        {{ row.course | optional('code') }}
        <div class="small text-truncate">{{ row.course | optional('title') }}</div>
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column.key"
      :label="column.label"
      align="center">
      <div slot-scope="{ row }">
        <div class="small" v-if="row.sales[column.key]">
          {{ row.sales[column.key][amountKey] | euro }}
        </div>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      :prop="totalKey"
      width="150"
      align="center">
      <div slot-scope="{ row }">
        {{ row[totalKey] | euro }}
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "SalePerformanceByCourseTableByAmountEntities",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    entityKey: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    amountKey() {
      return 'amount' + this.entityKey;
    },
    totalKey() {
      return 'totalAmount' + this.entityKey;
    }
  },
  methods: {
    getAmountSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }

        const values = this.$_.map(data, (item) => {
          return column.property === this.totalKey
            ? (item[this.totalKey] || 0)
            : item.sales?.[column.property]?.[this.amountKey] || 0;
        });

        sums[index] = this.$options.filters.euro(this.$_.sum(values));
      })
      return sums;
    }
  }
}
</script>

<style scoped>

</style>
