<template>
  <div>
    <el-table stripe :data="rows">
      <el-table-column
        width="150px"
        :label="$t('datatable.username')"
        align="left">
        <div slot-scope="{ row }">
          <div class="small text-truncate">{{ row.user }}</div>
        </div>
      </el-table-column>
      <el-table-column
        width="80px"
        :label="columnLabel('ch')"
        align="center">
        <div slot-scope="{ row }" class="small">
          {{ row.values.ch || '' }}
        </div>
      </el-table-column>
      <el-table-column
        width="90px"
        :label="columnLabel('la')"
        align="center">
        <div slot-scope="{ row }" class="small">
          {{ row.values.la || '' }}
        </div>
      </el-table-column>
      <el-table-column
        width="75px"
        :label="columnLabel('rpu')"
        align="center">
        <div slot-scope="{ row }" class="small">
          {{ row.values.rpu || '' }}
        </div>
      </el-table-column>
      <el-table-column
        width="75px"
        :label="columnLabel('rpr')"
        align="center">
        <div slot-scope="{ row }" class="small">
          {{ row.values.rpr || '' }}
        </div>
      </el-table-column>
      <el-table-column
        v-for="(column, index) in columns" v-bind:key="index"
        width="85px"
        :label="columnLabel(column)"
        align="center">
        <div slot-scope="{ row }" class="small">
          <report-value-container :name="column" :data="row.values[column]"/>
        </div>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import reportConst from "@/pages/Reports/resources/reportConst";
import Report from "@/models/report";
import {mapMutations} from "vuex";
import CallCenterOperatorPerformanceTableHelper from "@/components/Helpers/CallCenterOperatorPerformanceTableHelper";

export default {
  name: "CallCenterOperatorPerformanceTable",
  data() {
    return {
      reportConst: reportConst,
      helperComponent: CallCenterOperatorPerformanceTableHelper
    }
  },
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    rows: function () {
      return Object.values(this.report?.data || {})
    },
    columns: function () {
      return this.$_.remove(this.$_.keys(reportConst.callCenterOperatorPerformance), (item) => {
        return ['ch', 'la', 'rpu', 'rpr'].indexOf(item) === -1
      });
    }
  },
  mounted() {
    this.setHelper(this.helperComponent)
  },
  methods: {
    ...mapMutations({
      setHelper: 'common/setHelper',
    }),
    columnLabel: function (name) {
      switch (name) {
        case 'la':
          return 'Nomi Lv';
        case 'le':
          return 'Ch Uti';
        case 'li':
          return 'LINV';
        case 'dp':
          return 'ISCRITTI';
        case 'dc':
          return 'CHIUSI';
        case 'ln':
          return 'LNNINT';
        case 'dn':
          return 'DNNINT';
        case 'di':
          return 'DINV';
        case 'd':
          return 'App Fi';
        default:
          return name;
      }
    }
  },
  beforeDestroy() {
    this.setHelper()
  }
}
</script>

<style scoped>

</style>
