<template>
  <div>
    <tabs
      tabNavWrapperClasses="col-md-1"
      tabContentClasses="col-md-11"
      vertical
      square
      class="row"
    >
      <tab-pane v-for="(data, index) in report.data" v-bind:key="index" :label="data.acronym">
        <component
          :is="reportVsRegionChildTableComponent"
          :columns="columnsByRegion(data.acronym)"
          :data="data.values"
        />
      </tab-pane>
    </tabs>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {TabPane, Tabs} from '@/components';
import Report from "@/models/report";
import reportVsRegionChildTableComponentConst from "../resources/reportVsRegionChildTableComponentConst";

export default {
  name: "ReportVsRegionTable",
  data() {
    return {
      reportVsRegionChildTableComponentConst: reportVsRegionChildTableComponentConst
    }
  },
  components: {TabPane, Tabs},
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers'
    }),
    reportVsRegionChildTableComponent: function () {
      return reportVsRegionChildTableComponentConst[this.report.name];
    },
    titleByReportName() {
      switch (this.report.name) {
        case 'leadByProviderVsRegion':
          return 'distribution_by_lead';
        case 'leadAmountByProviderVsRegion':
          return 'distribution_by_amount';
        case 'dealAssignedByProviderVsRegion':
          return 'distribution_by_deal_as';
        case 'dealDoneByProviderVsRegion':
          return 'distribution_by_deal_fa';
        case 'dealClosedByProviderVsRegion':
          return 'distribution_by_deal_ch';
        case 'dealClosedAmountByProviderVsRegion':
          return 'distribution_by_deal_ch_amount';
      }
    }
  },
  methods: {
    columnsByRegion: function (region) {
      return this.$_.map(
        this.$_.keys(this.report.data?.[region]?.values?.[0]?.providers) || [],
        (item) => {
          return {
            'key': item,
            'acronym': this.providers[item]?.acronym || '--'
          }
        });
    },
  }
}
</script>

<style scoped>

</style>
