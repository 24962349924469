<template>
  <el-table stripe
            width="100%"
            show-summary
            :summary-method="getDealCHAmountSummaries"
            :data="data">
    <el-table-column
      :label="$t('datatable.course')"
      :width="300"
      align="left"
    >
      <div slot-scope="{ row }" >
        <div>{{ row.courseCode }}</div>
        <div class="small text-truncate">{{row.courseTitle}}</div>
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column.key"
      :label="column.acronym"
      align="center">
      <div slot-scope="{ row }">
        <div class="small text-nowrap" v-if="row.providers[column.key].dealsCHAmount">
          {{ row.providers[column.key].dealsCHAmount | euro }}
        </div>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      prop="total"
      align="center">
      <div slot-scope="{ row }">
        <div class="small text-nowrap">{{ row.dealsCHAmount | euro}}</div>
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";

export default {
  name: "LeadByProviderTableByDealChAmount",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getDealCHAmountSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }
        const values = this.$_.map(data, (item) => {
          return column.property === 'total'
            ? item?.dealsCHAmount || 0
            : item?.providers?.[column.property]?.dealsCHAmount || 0;
        })
        sums[index] = this.$options.filters.euro(this.$_.sum(values));
      })
      return sums;
    },
  }
}
</script>

<style scoped lang="scss">

</style>
