<template>
  <el-table stripe
            width="100%"
            show-summary
            :summary-method="getDealSummaries"
            :data="data"
  >
    <el-table-column
      :label="$t('fields.sale')"
      :width="200"
      align="left"
    >
      <div slot-scope="{ row }" >
        {{ users[row.user_id] | optional('firstname') }}
        {{ users[row.user_id] | optional('lastname') }}
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('deal.appointments')"
      prop="dealDates"
      align="center">
      <div slot-scope="{ row }">
        {{ row.values.dealDates }}
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column"
      :label="$t('deal.status.' + column)"
      align="center">
      <div slot-scope="{ row }">
        <report-value-container :name="column" :data="row.values[column]"/>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      prop="tot"
      align="center">
      <div slot-scope="{ row }">
        {{ row.values.tot.length }}
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "SalePerformanceTableByDeal",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  methods: {
    getDealSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }
        const values = this.$_.map(data, (item) => {
          if (column.property !== 'dealDates') {
            return item.values?.[column.property]?.length || 0;
          }
        })
        sums[index] = this.$_.sum(values);
      })
      return sums;
    },
  }
}
</script>

<style scoped>

</style>
