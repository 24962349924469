<template>
  <tabs square>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_provider') }}
            </span>
      <sale-closed-deals-table-by-provider :data="data" :columns="columnsProviders"/>
    </tab-pane>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_provider_amount') }}
            </span>
      <sale-closed-deals-table-by-provider-amount :data="data" :columns="columnsProviders"/>
    </tab-pane>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_course') }}
            </span>
      <sale-closed-deals-table-by-course :data="data" :columns="columnsCourses"/>
    </tab-pane>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_course_amount') }}
            </span>
      <sale-closed-deals-table-by-course-amount :data="data" :columns="columnsCourses"/>
    </tab-pane>
  </tabs>
</template>

<script>
import {mapGetters} from "vuex";
import Report from "@/models/report";
import {TabPane, Tabs} from "@/components";
import SaleClosedDealsTableByProvider from "./SaleClosedDealsTableByProvider";
import SaleClosedDealsTableByProviderAmount from "./SaleClosedDealsTableByProviderAmount";
import SaleClosedDealsTableByCourse from "./SaleClosedDealsTableByCourse";
import SaleClosedDealsTableByCourseAmount from "./SaleClosedDealsTableByCourseAmount";

export default {
  name: "SaleClosedDealsTable",
  data() {
    return {}
  },
  components: {
    SaleClosedDealsTableByCourseAmount,
    SaleClosedDealsTableByCourse,
    SaleClosedDealsTableByProviderAmount,
    SaleClosedDealsTableByProvider,
    TabPane,
    Tabs
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers',
      courses: 'common/allCourses'
    }),
    columnsProviders: function () {
      return this.$_.map(this.$_.keys(this.report?.data?.[2]?.values?.providers || []), (key, value) => {
        return {
          key: key,
          acronym: this.providers[key]?.acronym,
        };
      });
    },
    columnsCourses: function () {
      return this.$_.map(this.$_.keys(this.report?.data?.[2]?.values?.courses || []), (key, value) => {
        return {
          key: key,
          acronym: this.courses[key]?.code,
        };
      });
    },
    data: function () {
      return Object.values(this.report?.data) || [];
    }
  }
}
</script>

<style scoped>

</style>
