<template>
  <tabs square>
    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal') }}
      </span>
      <sale-performance-by-course-table-by-deals :columns="columns" :data="data"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="Deals"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal_fa') }}
      </span>
      <sale-performance-by-course-table-by-done-deals :columns="columns" :data="data"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal_fa_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="DoneDeals"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal_ch') }}
      </span>
      <sale-performance-by-course-table-by-closed-deals :columns="columns" :data="data"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_deal_ch_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="ClosedDeals"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="Leads"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_lead_ch_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="ClosedLeads"/>
    </tab-pane>

    <tab-pane>
      <span slot="label" class="text-uppercase">
        {{ $t('reports.distribution_by_lead_fa_amount') }}
      </span>
      <sale-performance-by-course-table-by-amount-entities :columns="columns" :data="data" entity-key="DoneLeads"/>
    </tab-pane>
  </tabs>
</template>

<script>
import {mapGetters} from "vuex";
import Report from "@/models/report";
import {TabPane, Tabs} from "@/components";
import SalePerformanceByCourseTableByDeals from "./SalePerformanceByCourseTableByDeals";
import SalePerformanceByCourseTableByClosedDeals from "./SalePerformanceByCourseTableByClosedDeals";
import SalePerformanceByCourseTableByDoneDeals from "./SalePerformanceByCourseTableByDoneDeals";
import SalePerformanceByCourseTableByAmountEntities from "./SalePerformanceByCourseTableByAmountEntities";

export default {
  name: "SalePerformanceByCourseTable",
  components: {
    SalePerformanceByCourseTableByAmountEntities,
    SalePerformanceByCourseTableByDoneDeals,
    SalePerformanceByCourseTableByClosedDeals,
    SalePerformanceByCourseTableByDeals,
    TabPane,
    Tabs
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    data: function () {
      return Object.values(this.report?.data) || [];
    },
    columns: function () {
      return this.$_.map(this.$_.keys(this.report?.data?.[1]?.sales || []), (key) => {
        return {
          key: key,
          label: this.$options.filters.userCode(this.users[key]),
        };
      });
    },
  }
}
</script>

<style scoped>

</style>
