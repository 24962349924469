import ReportVsRegionChildTable from "../components/ReportVsRegionChildTable";
import ReportVsRegionChildTableAmount from "../components/ReportVsRegionChildTableAmount";

const reportVsRegionChildTableComponentConst = {
  leadByProviderVsRegion: ReportVsRegionChildTable,
  leadAmountByProviderVsRegion: ReportVsRegionChildTableAmount,
  dealAssignedByProviderVsRegion: ReportVsRegionChildTable,
  dealDoneByProviderVsRegion: ReportVsRegionChildTable,
  dealClosedByProviderVsRegion: ReportVsRegionChildTable,
  dealClosedAmountByProviderVsRegion: ReportVsRegionChildTableAmount
}

export default reportVsRegionChildTableComponentConst
