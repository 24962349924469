import CallCenterOperatorPerformanceTable from "@/pages/Reports/components/CallCenterOperatorPerformanceTable";
import LeadByProviderTable from "@/pages/Reports/components/LeadByProviderTable";
import CallCenterOperatorBalancePerformanceTable
  from "@/pages/Reports/components/CallCenterOperatorBalancePerformanceTable";
import ReportVsRegionTable from "../components/ReportVsRegionTable";
import SalePerformanceTable from "../components/SalePerformanceTable";
import SaleClosedDealsTable from "../components/SaleClosedDealsTable";
import SalePerformanceByCourseTable from "../components/SalePerformanceByCourseTable";

const reportTableComponentConst = {
  callCenterOperatorPerformance: CallCenterOperatorPerformanceTable,
  callCenterOperatorBalancePerformance: CallCenterOperatorBalancePerformanceTable,
  leadByProvider: LeadByProviderTable,
  leadByProviderVsRegion: ReportVsRegionTable,
  leadAmountByProviderVsRegion: ReportVsRegionTable,
  dealAssignedByProviderVsRegion: ReportVsRegionTable,
  dealDoneByProviderVsRegion: ReportVsRegionTable,
  dealClosedByProviderVsRegion: ReportVsRegionTable,
  dealClosedAmountByProviderVsRegion: ReportVsRegionTable,
  salePerformanceIn: SalePerformanceTable,
  salePerformanceOut: SalePerformanceTable,
  saleClosedDeals: SaleClosedDealsTable,
  salePerformanceByCourse: SalePerformanceByCourseTable,
}

export default reportTableComponentConst
