<template>
  <div>
    <p class="text-body small">
      Questo report mostra l'operatività del call center e l'evoluzione dei lead fino a diventare opportunità.<br>
      Si possono controllare le iterazioni con la barra telefonica ( CHITOT ) e il numero totale dei lead che l'operatore
      ha preso in carico nel periodo selezionato ( LEATOT ).<br>
      Fornisce una valutazione dei lead esitati dall'operatore che hanno un date_out nell'intervallo richiesto ( LEAUTI )
      con un dettaglio per gli stati invalidi ( LI ) e non interessati ( LN )<br>
      Le opportunità prese in considerazione sono solo le opportuinità generate dai lead nel report ( LEAUTI ).
    </p>
    <base-alert type="info" class="my-2 text-uppercase">
      {{ $t('common.legend') }}
    </base-alert>
    <ul class="list-group list-group-flush">
      <li v-for="(legend, index) in reportConst.callCenterOperatorPerformance"
          v-bind:key="`performance-${index}`"
          class="standard-li">
        <div class="text-uppercase small">
          <span class="text-primary">{{ columnLabel(legend.name) }}</span> : {{ legend.description }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import reportConst from "@/pages/Reports/resources/reportConst";
import BaseAlert from "@/components/BaseAlert";

export default {
  name: "CallCenterOperatorPerformanceTableHelper",
  components: {BaseAlert},
  data() {
    return {
      reportConst: reportConst,
    }
  },
  methods: {
    columnLabel: function (name) {
      switch (name) {
        case 'ch':
          return 'chitot';
        case 'la':
          return 'leatot';
        case 'le':
          return 'leauti';
        default:
          return name;
      }
    }
  }
}
</script>

<style scoped>

</style>
