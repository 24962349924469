<template>
  <el-table stripe
            width="100%"
            show-summary
            :summary-method="getRegistrySummaries"
            :data="data">
    <el-table-column
      :label="$t('fields.sale')"
      :width="200"
      align="left"
    >
      <div slot-scope="{ row }" >
        {{ users[row.sale_id] | optional('firstname') }}
        {{ users[row.sale_id] | optional('lastname') }}
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column.key"
      :label="column.acronym"
      align="center">
      <div slot-scope="{ row }">
        <report-value-container :name="column.acronym" :data="row.values.courses[column.key].deals"/>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      prop="total"
      align="center">
      <div slot-scope="{ row }">
        {{ row.totalCourses }}
      </div>
    </el-table-column>
  </el-table>
</template>

<script>

import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";
export default {
  name: "SaleClosedDealsTableByCourse",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getRegistrySummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }
        const values = this.$_.map(data, (item) => {
          return column.property === 'total'
            ? item?.totalCourses || 0
            : item?.values?.courses?.[column.property]?.deals?.length || 0
        })
        sums[index] = this.$_.sum(values);
      })
      return sums;
    },
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  }
}
</script>

<style scoped>

</style>
