var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"stripe":"","width":"100%","show-summary":"","summary-method":_vm.getDealSummaries,"data":_vm.data}},[_c('el-table-column',{attrs:{"label":_vm.$t('fields.sale'),"width":200,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.users[row.user_id],'firstname'))+" "+_vm._s(_vm._f("optional")(_vm.users[row.user_id],'lastname'))+" ")])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('deal.appointments'),"prop":"dealDates","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.values.dealDates)+" ")])}}])}),_vm._l((_vm.columns),function(column,index){return _c('el-table-column',{key:index,attrs:{"prop":column,"label":_vm.$t('deal.status.' + column),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('report-value-container',{attrs:{"name":column,"data":row.values[column]}})],1)}}],null,true)})}),_c('el-table-column',{attrs:{"label":_vm.$t('common.total'),"prop":"tot","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.values.tot.length)+" ")])}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }