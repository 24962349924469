<template>
  <div class="row">
    <octo-header-button :buttons="headerButtons" @onExport="exportReport"/>
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <div slot="header" class="d-flex justify-content-between align-items-center">
          <h4 class="card-title" v-if="report.name">
            {{ $t('reports.types.' + report.name) }}
          </h4>
          <div>{{report.date_from | date}} - {{report.date_to | date}}</div>
        </div>
        <component :is="reportTableComponent" :report="report"/>
      </card>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";
import {Table, TableColumn} from "element-ui";
import {mapActions} from "vuex";
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import reportTableComponentConst from "@/pages/Reports/resources/reportTableComponentConst";
import reportConst from "@/pages/Reports/resources/reportConst";
import Report from "@/models/report";

export default {
  name: "ShowPage",
  components: {
    ReportValueContainer,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      reportName: this.$route.params.name,
      reportDateFrom: this.$route.params.dateFrom,
      reportDateTo: this.$route.params.dateTo,
      report: this.$_.cloneDeep(Report),
      reportConst : reportConst,
      reportTableComponentConst: reportTableComponentConst,
      headerButtons: [
        {
          label: 'export',
          onClick: 'onExport'
        }
      ],
    }
  },
  computed: {
    reportTableComponent: function () {
      return reportTableComponentConst[this.report.name];
    }
  },
  mounted() {
    this.$fullLoading.show();
    this.$api.get(endpoints.REPORTS_SHOW
      .replace('{id}', this.$route.params.id)
    )
      .then((resp) => {
        this.report = {
          ...resp?.data?.data,
          data: resp?.data?.meta?.data
        };

        this.$fullLoading.hide();
      })
      .catch((err) => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'reports.list'})
      })
  },
  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),

    exportReport: function () {
      this.downloadFile({
        url: endpoints.REPORTS_EXPORT.replace('{id}', this.report.id),
        filename: 'Report-' + this.report.name + '-' + this.report.date_from + '_' + this.report.date_to + '.xlsx',
        method: 'get',
      }).then()
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
        })
    },
  }

}
</script>

<style scoped>

</style>
