<template>
  <tabs square>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_deal') }}
            </span>
      <sale-performance-table-by-deal :data="data" :columns="columns"/>
    </tab-pane>
    <tab-pane>
            <span slot="label" class="text-uppercase">
              {{ $t('reports.distribution_by_deal_amount') }}
            </span>
      <sale-performance-table-by-amount :data="data" :columns="columns"/>
    </tab-pane>
  </tabs>
</template>

<script>
import {mapGetters} from "vuex";
import Report from "@/models/report";
import {TabPane, Tabs} from "@/components";
import SalePerformanceTableByDeal from "./SalePerformanceTableByDeal";
import reportConst from "../resources/reportConst";
import SalePerformanceTableByAmount from "./SalePerformanceTableByAmount";

export default {
  name: "SalePerformanceTable",
  components: {
    SalePerformanceTableByDeal,
    SalePerformanceTableByAmount,
    TabPane,
    Tabs
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers'
    }),
    data: function () {
      return Object.values(this.report?.data) || [];
    },
    columns() {
      return reportConst[this.report.name]?.columns;
    },
  }
}
</script>

<style scoped>

</style>
