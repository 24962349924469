<template>
  <el-table
            width="100%"
            stripe
            show-summary
            :summary-method="getDealSummaries"
            :data="data"
  >
    <el-table-column
      :label="$t('fields.courses')"
      :width="200"
      align="left"
    >
      <div slot-scope="{ row }" >
        {{ row.course | optional('code') }}
        <div class="small text-truncate">{{ row.course | optional('title') }}</div>
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column.key"
      :label="column.label"
      align="center">
      <div slot-scope="{ row }">
        <div class="small" v-if="row.sales[column.key]">
          <report-value-container :name="column.label" :data="row.sales[column.key].closedDeals"/>
        </div>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      prop="totalClosedDeals"
      width="150"
      align="center">
      <div slot-scope="{ row }">
        {{ row.totalClosedDeals }}
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "SalePerformanceByCourseTableByClosedDeals",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  methods: {
    getDealSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }

        const values = this.$_.map(data, (item) => {
          return column.property === 'totalClosedDeals'
            ? (item?.totalClosedDeals || 0)
            : item.sales?.[column.property]?.closedDeals?.length || 0;
        });

        sums[index] = this.$_.sum(values);
      })
      return sums;
    }
  }
}
</script>

<style scoped>

</style>
