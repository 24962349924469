var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"stripe":"","data":_vm.rows}},[_c('el-table-column',{attrs:{"width":"150px","label":_vm.$t('datatable.username'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"small text-truncate"},[_vm._v(_vm._s(row.user))])])}}])}),_c('el-table-column',{attrs:{"width":"80px","label":_vm.columnLabel('ch'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(row.values.ch || '')+" ")])}}])}),_c('el-table-column',{attrs:{"width":"90px","label":_vm.columnLabel('la'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(row.values.la || '')+" ")])}}])}),_c('el-table-column',{attrs:{"width":"75px","label":_vm.columnLabel('rpu'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(row.values.rpu || '')+" ")])}}])}),_c('el-table-column',{attrs:{"width":"75px","label":_vm.columnLabel('rpr'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(row.values.rpr || '')+" ")])}}])}),_vm._l((_vm.columns),function(column,index){return _c('el-table-column',{key:index,attrs:{"width":"85px","label":_vm.columnLabel(column),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_c('report-value-container',{attrs:{"name":column,"data":row.values[column]}})],1)}}],null,true)})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }