<template>
  <el-table
            width="100%"
            stripe
            show-summary
            :summary-method="getAmountSummaries"
            :data="data"
  >
    <el-table-column
      :label="$t('fields.sale')"
      :width="200"
      align="left"
    >
      <div slot-scope="{ row }" >
        {{ users[row.user_id] | optional('firstname') }}
        {{ users[row.user_id] | optional('lastname') }}
      </div>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns" v-bind:key="index"
      :prop="column + '_amount'"
      :label="$t('deal.status.' + column)"
      align="center">
      <div slot-scope="{ row }">
        <div class="small" v-if="row.values[column + '_amount']">
          {{ row.values[column + '_amount'] | euro }}
        </div>
      </div>
    </el-table-column>
    <el-table-column
      :label="$t('common.total')"
      prop="tot_amount"
      width="150"
      align="center">
      <div slot-scope="{ row }">
        {{ row.values.tot_amount | euro}}
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import ReportValueContainer from "@/pages/Reports/components/ReportValueContainer";
import {Table, TableColumn} from "element-ui";
import {mapGetters} from "vuex";

export default {
  name: "SalePerformanceTableByAmount",
  components: {
    ReportValueContainer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  methods: {
    getAmountSummaries: function (param) {
      const {columns, data} = param;
      const sums = [];
      this.$_.each(columns, (column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.total');
          return;
        }

        const values = this.$_.map(data, (item) => {
          return item.values?.[column.property] || 0;
        });

        sums[index] = this.$options.filters.euro(this.$_.sum(values));
      })
      return sums;
    }
  }
}
</script>

<style scoped>

</style>
