const reportConst = {
  callCenterOperatorPerformance: {
    ch: {
      name: 'ch',
      description: "Numero interazioni con barra telefonica"
    },
    la: {
      name: 'la',
      description: "Ogni volta che l'operatore ha preso in carico un lead"
    },
    rpu : {
      name: 'rpu',
      description: 'Ogni volta che l\'operatore ha impostato il lead come RICHIAMO PUBBLICO',
    },
    rpr : {
      name: 'rpr',
      description: 'Ogni volta che l\'operatore ha impostato il lead come RICHIAMO PRIVATO',
    },
    le : {
      name: 'le',
      description: "Tutti i lead dell'operatore che hanno date_out compresa nell'intervallo",
    },
    li : {
      name: 'li',
      description: 'Lead presenti nei LEAUTI e impostati come invalidi',
    },
    ln : {
      name: 'ln',
      description: 'Lead presenti nei LEAUTI e impostati come non interessati',
    },
    d : {
      name: 'd',
      description: "Opportunità escluse le NON CONFERMATE che hanno un lead con date_out compreso nell'intervallo",
    },
    dc : {
      name: 'dc',
      description: 'Opportunità presenti in D e chiuse dal consulente',
    },
    dp : {
      name: 'dp',
      description: 'Opportunità presenti in D e trasformate in studenti',
    },
    di : {
      name: 'di',
      description: 'Opportunità presenti in D ed esitate come non valide',
    },
    dn : {
      name: 'dn',
      description: 'Opportunità presenti in D ed esitate come non interessate',
    },
    dl : {
      name: 'dl',
      description: 'Opportunità presenti in D e in lavorazione',
    }

  },
  leadByProvider: null,
  leadByProviderVsRegion: null,
  leadAmountByProviderVsRegion: null,
  dealAssignedByProviderVsRegion: null,
  dealDoneByProviderVsRegion: null,
  dealClosedByProviderVsRegion: null,
  dealClosedAmountByProviderVsRegion: null,
  salePerformanceIn: {
    columns: ['closed', 'confirmed', 'processing', 'promoted', 'finished'],
  },
  salePerformanceOut: {
    columns: ['closed', 'promoted', 'rejected'],
  },
  saleClosedDeals: null,
  salePerformanceByCourse: null,
}

export default reportConst
