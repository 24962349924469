<template>
  <div>
    <div class="mb-3">
      <p class="small text-body">
        Questo report fornisce una valutazione delle lead entrate nel periodo selezionato e la loro evoluzione
        divisa in corsi e provider e divise nelle seguenti tab:<br>
        LEADS: Suddivisione di tutte le lead entrate<br>
        COSTO LEADS: Costo delle leads entrate<br>
        OPPORTUNITA' ASSEGNATE: Lead che sono in stato PROMOTED e che hanno generato
        una opportunità ( AS nel vecchio report )<br>
        OPPORTUNITA' FATTE: Lead che sono in stato PROMOTED e che hanno
        una opportunità ( FA nel vecchio report ) nello stato CHIUSO, IN SOSPESO, NON INTERESSATO o
        PROMOSSO<br>
        OPPORTUNITA' CHIUSE: Lead che sono in stato PROMOTED e che hanno
        una opportunità ( CH nel vecchio report ) nello stato CHIUSO o PROMOSSO<br>
        OPPORTUNITA' CHIUSE IMPORTO: IMporto corrispondente alle OPPORTUNITA' CHIUSE<br>
      </p>
    </div>
    <tabs square>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_lead')}}
            </span>
        <lead-by-provider-table-by-lead
          :data="data"
          :columns="columns" />
      </tab-pane>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_amount')}}
            </span>
        <lead-by-provider-table-by-amount
          :data="data"
          :columns="columns" />
      </tab-pane>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_deal_as')}}
            </span>
        <lead-by-provider-table-by-deal-as
          :data="data"
          :columns="columns" />
      </tab-pane>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_deal_fa')}}
            </span>
        <lead-by-provider-table-by-deal-fa
          :data="data"
          :columns="columns" />
      </tab-pane>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_deal_ch')}}
            </span>
        <lead-by-provider-table-by-deal-ch
          :data="data"
          :columns="columns" />
      </tab-pane>
      <tab-pane>
            <span slot="label" class="text-uppercase">
              {{$t('reports.distribution_by_deal_ch_amount')}}
            </span>
        <lead-by-provider-table-by-deal-ch-amount
          :data="data"
          :columns="columns" />
      </tab-pane>
    </tabs>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import Report from "@/models/report";
import LeadByProviderTableByDealCh from "@/pages/Reports/components/LeadByProviderTableByDealCh";
import LeadByProviderTableByDealFa from "@/pages/Reports/components/LeadByProviderTableByDealFa";
import LeadByProviderTableByDealAs from "@/pages/Reports/components/LeadByProviderTableByDealAs";
import LeadByProviderTableByAmount from "@/pages/Reports/components/LeadByProviderTableByAmount";
import LeadByProviderTableByLead from "@/pages/Reports/components/LeadByProviderTableByLead";
import {TabPane, Tabs} from "@/components";
import LeadByProviderTableByDealChAmount from "@/pages/Reports/components/LeadByProviderTableByDealChAmount";

export default {
  name: "LeadByProviderTable",
  data() {
    return {}
  },
  components: {
    LeadByProviderTableByDealChAmount,
    LeadByProviderTableByLead,
    LeadByProviderTableByAmount,
    LeadByProviderTableByDealAs,
    LeadByProviderTableByDealFa,
    LeadByProviderTableByDealCh,
    TabPane,
    Tabs
  },
  props: {
    report: {
      type: Object,
      default: () => this.$_.cloneDeep(Report)
    },
  },
  computed: {
    ...mapGetters({
      providers: 'common/providers'
    }),
    columns: function () {
      return this.$_.map(this.$_.keys(this.report?.data?.[0]?.providers || []), (item) => {
        return {
          'key' : item,
          'acronym' : this.providers[item]?.acronym || '--'
        }
      });
    },
    data: function () {
      return this.report?.data || [];
    }
  },
  methods: {
    columnsByRegion: function () {
      return this.$_.map(
        this.$_.keys(this.report.data?.[region]?.values?.providers) || [],
        (item) => {
          return {
            'key': item,
            'acronym': this.providers[item]?.acronym || '--'
          }
        });
    },
  }
}
</script>

<style scoped>

</style>
